
const Client = require('./client');
const qs = require('qs')
class SoalType{
    constructor(){
        this._client = new Client();
        this._endpoint = 'soaltype';
        this._endpointPaket = 'paket';
    }
    create(data){
        return new Promise(function(resolve,reject){
            this._client.post(this._endpoint,data)
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    data(id_matpel){
        return new Promise(function(resolve,reject){
            this._client.get(this._endpoint+"?id_matpel="+id_matpel)
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    pondok(){
      return new Promise(function(resolve,reject){
          this._client.get(this._endpointPaket+"?isPondok=1" )
          .then((result)=>{
              resolve(result)
          })
          .catch(err=>reject(err));
      }.bind(this))
    }
    edit(data){
        return new Promise(function(resolve,reject){
            this._client.put(this._endpoint+"/"+data.id,qs.stringify({
                area: data.name
              }))
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    hapus(data){
        return new Promise(function(resolve,reject){
            this._client.delete(this._endpoint+"/"+data.id)
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
}

module.exports = SoalType;

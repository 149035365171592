<script>

// eslint-disable-next-line no-unused-vars
import Layout from "../../layouts/main";
// eslint-disable-next-line no-unused-vars
import PageHeader from "@/components/page-header";
const Soal = require('./../../../api/soal');
const SoalType = require('./../../../api/soalType');
const Matpel = require('./../../../api/matpel');
const Jenjang = require('./../../../api/jenjang');
import Swal from "sweetalert2";

export default {
  // eslint-disable-next-line vue/no-unused-components
    components: { Layout, PageHeader },
    page: {
        title: "PaketSoal",
        meta: [{ name: "description", content: "List Soal" }]
    },
    data() {
        return {
            title: "PaketSoal",
            items: [
                {
                text: "PaketSoal",
                active: true
                }
            ],
            dismissSecs: 5,
            dismissCountDown: 0,
            lists : [],
            list : null,
            types : [],
            type : null,
            matpels : [],
            matpel : 0,
            jenjangs : [],
            id_jenjang : 0,
            id:null,
            name :'',
            loading : false,
            state : 'list',
            isError :false,
            message : '',
            Soal : new Soal(),
            soalType : new SoalType(),
            Jenjang : new Jenjang(),
            Matpel : new Matpel(),
            totalRows: 1,
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 100],
            filter: null,
            filters : {},
            filterOn: [],
            sortBy: "type",
            sortDesc: true,
            selectMode: 'multi',
            modes: ['multi', 'single', 'range'],
            fields: [
                { key: "idSoal",label:"id", sortable: false },
                { key: "Soals",label:"Soal", sortable: true },
                { key: "type",label:"type soal", sortable: true },
                // { key: "Actions", sortable: false },
                { key: "selected", sortable: false }
            ],
            selected: [],
            isBusy: true,
        }
    },
    computed: {
        /**
         * Total no. of records
         */
        rows() {
            return this.lists.length;
        },
        user: function () {
          return this.$store.getters["users/user"];
        },
    },
    mounted(){
        this.getMatpel();
        this.getJenjang();
        this.getType();
        // this.getData();
        this.totalRows = this.items.length;
    },
    created() {
      // Use a non-reactive property to prevent
      // unnecessary re-renders of your component
      this.$_timeout = null
    },
    beforeDestroy() {
      // Ensure that any pending timeout is cleared on destroy
      clearTimeout(this.$_timeout)
    },
    methods: {
        handleFilter(val) {
          console.log(val);
          clearTimeout(this.$_timeout)
          this.$_timeout = setTimeout(() => {
            this.filter = val
          }, 150) // set this value to your preferred debounce timeout
        },
        countDownChanged(dismissCountDown) {
          this.dismissCountDown = dismissCountDown
        },
        showAlert() {
          this.dismissCountDown = this.dismissSecs
        },
        onRowSelected(items) {
          this.selected = items
        },
        selectAllRows() {
          this.$refs.selectableTable.selectAllRows()
        },
        clearSelected() {
          this.$refs.selectableTable.clearSelected()
        },
        selectThirdRow() {
          // Rows are indexed from 0, so the third row is index 2
          this.$refs.selectableTable.selectRow(2)
        },
        unselectThirdRow() {
          // Rows are indexed from 0, so the third row is index 2
          this.$refs.selectableTable.unselectRow(2)
        },
        /**
         * Search the table data with search input
         */
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        getData(){
            this.filters = {};
            if(this.matpel != null){
                // this.filters.id_type = this.matpel
                this.filters.id_matpel = this.type
            }
            console.log(this.filters);
            this.Soal.dataSoalPaket(this.filters)
            .then((res)=>{
                if(!res.success){
                    this.setError(true,res.data)
                }else{
                    this.lists = res.data_soal;
                    this.isBusy = !this.isBusy;
                    // this.showList();
                }
            })
            .catch((err)=>{
                this.setError(true,err);
            })
        },
        getType(){
            this.soalType.data(this.matpel)
            .then((res)=>{
                if(!res.success){
                    this.setError(true,res.data)
                }else{
                    this.types = res.data_soal_type.data;
                }
            })
            .catch((err)=>{
                this.setError(true,err);
            })
        },
        getJenjang(){
            this.Jenjang.data()
            .then((res)=>{
                if(!res.success){
                    this.setError(true,res.data)
                }else{
                    this.jenjangs = res.data;
                }
            })
            .catch((err)=>{
                this.setError(true,err);
            })
        },
        getMatpel(){
            this.Matpel.data(this.id_jenjang)
            .then((res)=>{
                if(!res.success){
                    this.setError(true,res.data)
                }else{
                    this.matpels = res.data.data;
                }
            })
            .catch((err)=>{
                this.setError(true,err);
            })
        },
        showCreate(){
            this.state = 'create'
        },
        print(){
            this.state = 'create'
        },
        showList(){
            this.state = 'list'
            // this.getData();
            this.reset();
        },
        save(){
            if (this.type != null) {
              for (var i = 0; i < this.selected.length; i++) {
                this.$store.dispatch('soal/saveSoalPaket',{soal:[this.selected[i]],types:this.type,user:this.user.data_user.id})
              }
              this.showList()
            }else{
              this.showAlert();
            }
        },
        setError(error,message){
            this.isError = error;
            this.message = message;
            this.loading = false;
        },
        // edit(row){
        edit(){
            // this.id = row.item.id
            // this.name = row.item.type
            if (this.selected.length === 0) {
              this.showAlert()
            }else{
              this.showCreate()
            }
        },
        reset(){
            this.id = 0;
            this.matpel = null;
            this.type = null;
            this.filters = {};
            this.name = '';
            this.loading = false;
            this.message ='';
            this.isError = false;
            this.lists = [];
            this.isBusy = true;
        },
        hapus(row){
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                confirmButton: "btn btn-success",
                cancelButton: "btn btn-danger ml-2"
                },
                buttonsStyling: false
            });
            Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes, delete it!"
            }).then(result => {
                if (result.value) {
                    this.Area.hapus({id:row.item.id})
                    .then((res)=>{
                        this.getData()
                        if(res.success){
                            Swal.fire("Deleted!", "Your file has been deleted.", "success");
                        }else{
                            swalWithBootstrapButtons.fire(
                            "Cancelled",
                            res.data_area,
                            "error"
                            );
                        }
                    })
                    .catch((err)=>{
                            swalWithBootstrapButtons.fire(
                            "Cancelled",
                            err,
                            "error"
                            );
                    })
                }
            });
        }
    },
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
        <div class="col-md-12">
            <div class="card" v-show="state === 'list'">
                <div class="card-body">
                    <div class="d-flex flex-row justify-content-between align-items-center">
                      <div class="col-sm-12 col-md-8">
                        <div class="card">
                          <div class="card-body">
                            <h4 class="card-title">Filter : </h4>
                            <div class="form-group">
                              <label class="control-label">Jenjang <span class="text-danger">*</span></label>
                              <select class="form-control" v-model="id_jenjang" @change="getMatpel()">
                                <option :value="null" hidden>Select Jenjang</option>
                                <option v-for="item in jenjangs" :key="item.id" :value="item.id">{{item.jenjang}}</option>
                              </select>
                            </div>
                            <div class="form-group">
                                <label class="control-label">Mata Pelajaran <span class="text-danger">*</span></label>
                                <select class="form-control" v-model="matpel" @change="getType()">
                                    <option :value="null" hidden>Select Mata Pelajaran</option>
                                    <option v-for="item in matpels" :key="item.id" :value="item.id">{{item.nama}}</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label class="control-label">Paket Soal <span class="text-danger">*</span></label>
                                <select class="form-control" v-model="type" @change="getData()">
                                    <option :value="null" hidden>Select Paket Soal</option>
                                    <option v-for="item in types" :key="item.id" :value="item.id">{{item.type}}</option>
                                </select>
                            </div>
                            <h6>Total : {{lists.length}}</h6>
                          </div>
                        </div>
                        <div class="card">
                            <div class="card-body">
                                <button class="btn btn-warning m-2" @click="reset()"><i class="bx bx-reset"></i> Reset</button>
                                <button class="btn btn-success m-2" @click="edit()"><i class="bx bx-plus-circle"></i>Ubah Paket yg diceklis</button>
                            </div>
                            <b-alert
                              :show="dismissCountDown"
                              dismissible
                              variant="warning"
                              @dismissed="dismissCountDown=0"
                              @dismiss-count-down="countDownChanged"
                            >
                              Belum ada yg di ceklis {{ dismissCountDown }}
                            </b-alert>
                        </div>
                      </div>
                        <!-- <h4 class="card-title">Data Soal</h4> -->
                        <!-- <p>
                          Selected Rows:<br>
                          {{ selected }}
                        </p> -->
                    </div>
                    <div class="row mt-4">
                        <div class="col-sm-8 col-md-6">
                            <div id="tickets-table_length" class="dataTables_length">
                                <label class="d-inline-flex align-items-center">
                                    Show&nbsp;
                                    <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                                </label>
                            </div>
                        </div>
                        <!-- Search -->
                        <div class="col-sm-12 col-md-6">
                            <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                <label class="d-inline-flex align-items-center">
                                    Search:
                                    <b-form-input
                                    @update="handleFilter" type="search"
                                    placeholder="Search..."
                                    class="form-control form-control-sm ml-2"
                                    ></b-form-input>
                                </label>
                            </div>
                        </div>
                        <!-- End search -->
                    </div>
                        <!-- Table -->
                    <div class="table-responsive mb-0">
                        <b-table
                            :items="lists"
                            :busy.sync="isBusy"
                            :fields="fields"
                            :select-mode="selectMode"
                            responsive="sm"
                            ref="selectableTable"
                            selectable
                            :per-page="perPage"
                            :current-page="currentPage"
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="sortDesc"
                            :filter="filter"
                            :filter-included-fields="filterOn"
                            @filtered="onFiltered"
                            @row-selected="onRowSelected"
                        >
                            <template #table-busy>
                              <div class="text-center text-danger my-2">
                                <b-spinner class="align-middle"></b-spinner>
                                <strong>Silahkan pilih matpel, jika sudah mohon Tunggu...</strong>
                              </div>
                            </template>
                            <template #cell(Soals)="row">
                                <p v-html="row.item.soals"></p>
                                <!-- <button class="btn btn-danger btn-sm  mx-1"  v-b-tooltip.hover title="Delete" @click="hapus(row)"><i class="bx bx-trash"></i></button> -->
                            </template>
                            <!-- <template #cell(Actions)="row">
                                <button class="btn btn-warning btn-sm mx-1" @click="edit(row)"  v-b-tooltip.hover title="Edit"><i class="bx bx-edit"></i></button> -->
                                <!-- <button class="btn btn-danger btn-sm  mx-1"  v-b-tooltip.hover title="Delete" @click="hapus(row)"><i class="bx bx-trash"></i></button> -->
                            <!-- </template> -->
                            <template #cell(selected)="{ rowSelected }">
                              <template v-if="rowSelected">
                                <span aria-hidden="true">&check;</span>
                                <span class="sr-only">Selected</span>
                              </template>
                              <template v-else>
                                <span aria-hidden="true">&nbsp;</span>
                                <span class="sr-only">Not selected</span>
                              </template>
                            </template>
                        </b-table>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="dataTables_paginate paging_simple_numbers float-right">
                            <ul class="pagination pagination-rounded mb-0">
                                <!-- pagination -->
                                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                            </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card" v-show="state === 'create'">
                <div class="card-body">
                    <div class="d-flex flex-row justify-content-start align-items-center">
                        <a class="btn text-danger  font-size-34" @click="showList()"><i class="bx bx-left-arrow-alt"></i></a>
                        <h4 class="card-title">Edit Paket Soal</h4>
                    </div>
                    <div class="m-2" v-for="(item,index) in selected" :key="item.idSoal">
                      <p v-html="item.soals"><b>{{index+1}}. </b></p>
                      <hr>
                    </div>
                    <div class="row  d-flex flex-column justify-content-center align-items-center">
                        <div class="col-md-5">
                            <b-alert variant="danger" :show="isError">{{message}}</b-alert>
                            <div class="form-group">
                              <label class="control-label">Jenjang <span class="text-danger">*</span></label>
                              <select disabled class="form-control" v-model="id_jenjang">
                                <option :value="null" hidden>Select Jenjang</option>
                                <option disabled v-for="item in jenjangs" :key="item.id" :value="item.id">{{item.jenjang}}</option>
                              </select>
                            </div>
                            <div class="form-group">
                              <label class="control-label">Mata Pelajaran <span class="text-danger">*</span></label>
                              <select disabled class="form-control" v-model="matpel">
                                <option :value="null" hidden>Select Mata Pelajaran</option>
                                <option disabled v-for="item in matpels" :key="item.id" :value="item.id">{{item.nama}}</option>
                              </select>
                            </div>
                            <div class="form-group">
                              <label class="control-label">Paket Soal <span class="text-danger">*</span></label>
                              <select class="form-control" v-model="type">
                                <option :value="null" hidden>Select Paket Soal</option>
                                <option v-for="item in types" :key="item.id" :value="item.id">{{item.type}}</option>
                              </select>
                            </div>
                            <div class="form-group">
                                <button class="btn btn-sm btn-primary" @click="save()" :disabled="loading"> <i v-if="loading" class="bx bx-loader bx-spin"></i> {{loading ? 'Loading':'Save Data'}}</button>
                                <b-alert
                                  :show="dismissCountDown"
                                  dismissible
                                  variant="warning"
                                  @dismissed="dismissCountDown=0"
                                  @dismiss-count-down="countDownChanged"
                                >
                                  paket soal belum dipilih {{ dismissCountDown }}
                                </b-alert>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </Layout>
</template>


const Client = require('./client');
const Jenjang = require('./jenjang');
const SoalType = require('./soalType');
const qs = require('qs')
class Matpel extends Client{
    constructor(){
        super();
        // this._client = new Client();
        this._jenjang = new Jenjang();
        this._soalType = new SoalType();
        this._endpoint = 'matpel';
    }
    create(data){
        return new Promise(function(resolve,reject){
            this.post(this._endpoint,data)
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    data(id_jenjang){
        return new Promise(function(resolve,reject){
            this.get(this._endpoint+"?id_jenjang="+id_jenjang)
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    edit(id,data){
        return new Promise(function(resolve,reject){
            this.put(this._endpoint+"/"+id,data)
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    hapus(data){
        return new Promise(function(resolve,reject){
            this.delete(this._endpoint+"/"+data.id)
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    getList(){
        return new Promise(function(resolve,reject){
            this._jenjang.data()
            .then(async (jenjangs)=>{
                var datajenjangs = await Promise.all(jenjangs.data.map(async function (item){
                    var matpels = await this.data(item.id)
                    var datamatpels = await Promise.all(matpels.data.data.map(async function (m){
                        var soaltypes = await this._soalType.data(m.id);
                        return {
                            id : m.id,
                            matpel : m.nama,
                            active : m.active,
                            totalPaket : soaltypes.data_soal_type.data.length
                        }
                    }.bind(this)));
                    return {
                        id : item.id,
                        jenjang : item.jenjang,
                        matpels : datamatpels
                    }
                }.bind(this)));
                resolve(datajenjangs);
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    save(dataMatpel,states){
        return new Promise(function(resolve,reject){
            if(dataMatpel.jenjang == null){
                states.error = true;
                states.loading = false;
                states.message = "Jenjang Not Found !"
                reject(states);
            }else if(dataMatpel.name == ""){
                states.error = true;
                states.loading = false;
                states.message = "This value is required."
                reject(states);
            }else if(dataMatpel.id == null){
                let data = qs.stringify({
                    matpel: dataMatpel.name,
                    id_jenjang: dataMatpel.jenjang.id
                })
                this.create(data)
                .then((res)=>{
                    if(res.success){
                        states.error = false;
                        states.loading = false;
                        states.message = res.data
                        states.isCreate = false
                        resolve(states);
                    }else{
                        states.error = true;
                        states.loading = false;
                        states.message = res.data
                        reject(states);
                    }
                })
                .catch(e=>{
                    states.error = true;
                    states.loading = false;
                    states.message = e
                    reject(states);
                })
            }else{
                let data = qs.stringify({
                    matpel: dataMatpel.name,
                    id_jenjang: dataMatpel.jenjang.id
                })
                this.edit(dataMatpel.id,data)
                .then((res)=>{
                    if(res.success){
                        states.error = false;
                        states.loading = false;
                        states.message = res.data_matpel
                        states.isCreate = false
                        resolve(states);
                    }else{
                        states.error = true;
                        states.loading = false;
                        states.message = res.data_matpel
                        reject(states);
                    }
                })
                .catch(e=>{
                    states.error = true;
                    states.loading = false;
                    states.message = e
                    reject(states);
                })
            }
        }.bind(this));
    }
}

module.exports = Matpel;